import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AuthService } from './auth.service';
import * as firebase from 'firebase';
import * as _ from 'lodash';
import global from 'environments/global';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { FirebaseKeyHandler } from '../models/firebase_key_handler';
import { CommonAPIService } from './common-api.service';
import { getRates } from '@milancrest274/price-calculations';
@Injectable()
export class ShipengineService {

    apiKey: any = '';
    carrier_ids: any = [];
    key_created = null;
    options: any;
    carriers:any;
    isUserAuthorized: any;

    constructor(
        private http: HttpClient,
        private db: AngularFireDatabase,
        private auth: AuthService,
        private commonAPIService:CommonAPIService
    ) {
        this.getDefaults();
        this.isUserAuthorized = this.auth.isUserAuthorized();
    }

    async getDefaults() {
        let settings: any = await this.commonAPIService.getSystemSettings();
        console.log('settings =', settings);
        if(settings) {
            if (settings.shipEngineKey) {
                this.apiKey = settings.shipEngineKey;
                console.log('this.apiKey =', this.apiKey);
            }
            if (settings.carriers) {
                this.carriers = settings.carriers;
                this.carrier_ids = JSON.parse(settings.carriers);
                console.log('this.carrier_ids =', this.carrier_ids);
            }
        }
    }

    // getRates(order_id, ship_to, ship_from, packageWeights) {
    //     let shipping_packages = [];
    //     let headers = new HttpHeaders({
    //         'Content-Type': 'application/json',
    //         'api-key': this.apiKey,
    //         'Target-URL': 'https://api.shipengine.com/v1/rates',
    //         'Access-Token': environment.config.api_key,
    //         'Auth-Token': localStorage.getItem('auth-token')
    //     });

    //     if (packageWeights && packageWeights.length > 0) {
    //         _.forEach(packageWeights, pack => {
    //             shipping_packages.push({
    //                 "weight": {
    //                     "value": pack.weight,
    //                     "unit": "ounce"
    //                 }
    //             })
    //         });
    //     } else {
    //         shipping_packages = [
    //             {
    //                 "weight": {
    //                     "value": 1.0,
    //                     "unit": "ounce"
    //                 }
    //             }
    //         ]
    //     }

    //     let data = {
    //         "rate_options": {
    //             "carrier_ids": this.carrier_ids.length > 0 ? this.carrier_ids : environment.config.carrier_ids
    //         },
    //         "shipment": {
    //             "validate_address": "no_validation",
    //             "ship_to": ship_to,
    //             "ship_from": ship_from,
    //             "packages": shipping_packages
    //         }
    //     };

    //     this.saveReqDataInApiLogs(order_id, 'getRates', data);

    //     return this.http.post(environment.config.proxy_url, data, { headers: headers });
    // }

    // Get Shipping Rates using npm package
    getRates(order_id, ship_to, ship_from, packageWeights, isSelfCheckout = false, customsPayload = []) {
        let authToken;
        if (!isSelfCheckout) {
            authToken = this.commonAPIService.fnGetAuthToken();
        } else {
            authToken = environment.config.SELF_CHECKOUT_ACCESS_TOKEN;
        }
        let payload = {
            apiKey: this.apiKey,
            api_key: environment.config.api_key,
            carrier_ids: this.carrier_ids,
            environment_carrier_ids: environment.config.carrier_ids,
            proxy_url: environment.config.proxy_url,
            authToken,
            key_created: this.key_created,
            userId: isSelfCheckout ? environment.config.SELF_CHECKOUT_USER_ID : this.auth.getUID(),
            targetURL: 'https://api.shipengine.com/v1/rates',
        }
        const { country_code: fromCountry } = ship_from;
        const { country_code: toCountry } = ship_to;

        let isCustomRequired = !(fromCountry.toUpperCase() === toCountry.toUpperCase());
        payload['isCustomRequired'] = isCustomRequired;

        if (isCustomRequired) {
            const customsItems = customsPayload.map(item => ({
                "harmonized_tariff_code": environment.config.harmonized_tariff_code,
                "country_of_manufacture": environment.config.country_of_manufacture,
                "country_of_origin": environment.config.country_of_origin,
                "quantity": item.quantity,
                "description": item.description,
                "value": {
                    "currency": environment.config.currency,
                    "amount": item.value
                }
            }));

            const customsPayloadData = {
                "contents": environment.config.contents,
                "non_delivery": environment.config.non_delivery,
                "customs_items": customsItems
            };

            payload['customsPayloadData'] = customsPayloadData;
        }
        return getRates(order_id, ship_to, ship_from, packageWeights, payload);
    }

    getEstimateRates(order_id, ship_to, ship_from, packageWeights) {
        let packageWeight = 0.0;
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'api-key': this.apiKey,
            'Target-URL': 'https://api.shipengine.com/v1/rates/estimate',
            'Access-Token': environment.config.api_key,
            
        });
        if(this.isUserAuthorized && localStorage.getItem('auth-token')){
            headers = headers.set('Auth-Token', localStorage.getItem('auth-token'));
        }
        
        // Iterate through each weight in the array of packageWeights
        if (packageWeights && packageWeights.length > 0) {
            for (const weight of packageWeights) {
                // Check if packageWeight is undefined or the current weight is greater than the stored packageWeight
                if (!packageWeight || weight.weight > packageWeight) {
                // Update packageWeight with the current weight if it's greater
                  packageWeight = weight.weight;
                }
            }
        } else {
            packageWeight = 1.0;
        }

        // if (packageWeights && packageWeights.length > 0) {
        //     // packageWeight = <number>_.last(_.sortBy(_.map(packageWeights, 'weight')));
        //     packageWeight = <number>_.sum(_.sortBy(_.map(packageWeights, 'weight')));
        // } else {
        //     packageWeight = 1.0;
        // }

        let data = {
            "carrier_ids": this.carrier_ids.length > 0 ? this.carrier_ids : environment.config.carrier_ids,
            "from_country_code": ship_from.country_code,
            "from_postal_code": ship_from.postal_code,
            "to_country_code": ship_to.country_code,
            "to_postal_code": ship_to.postal_code,
            "weight": {
                "value": packageWeight,
                "unit": "ounce"
            },
            "confirmation": "none",
            "address_residential_indicator": "no"
        };

        if(this.isUserAuthorized){
            this.saveReqDataInApiLogs(order_id, 'getEstimateRates', data);
        }
        
        return this.http.post(environment.config.proxy_url, data, { headers: headers });
    }

    createLabels(order_id, data) {
        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            'api-key': this.apiKey,
            'Target-URL': 'https://api.shipengine.com/v1/labels',
            'Access-Token': environment.config.api_key,
            'Auth-Token': localStorage.getItem('auth-token')
        });

        this.saveReqDataInApiLogs(order_id, 'createLabels', data);
        return this.http.post(environment.config.proxy_url, data, { headers: headers });
    }

    saveReqDataInApiLogs(orderId, api_type, req_data) {
        const reqData = {
            userId: this.auth.getUID(),
            orderId,
            requestData: JSON.stringify(req_data),
            apiType: api_type,
            api: 'shipengine'
        };

        this.commonAPIService.fnSaveReqDataInApiLogs(reqData).then((res?: any) => {
            this.key_created = res.logId || null;
        });
    }

    saveResDataInApiLogs(res_data) {
        if (this.key_created) {
            const dataObj = {
                responseData: JSON.stringify(res_data),
                logId: this.key_created
            };
            this.commonAPIService.fnSaveResDataInApiLogs(dataObj).then((res) => {
                this.key_created = null;
            });
        }
    }

    removeServicesFromList(serviceList: Array<any>, usps_first_class_mail, bulkShippingServiceType) {
        serviceList = serviceList.filter(rate => {
            if (rate.service_code == 'usps_media_mail' || rate.service_code == 'usps_parcel_select') {
                return false;
            }
            if (rate.service_code == "usps_first_class_mail" && usps_first_class_mail == true) {
                return false;
            }
            return rate;
        });

        return serviceList.map(rate => {
            rate.service_type = this.removeTrademarkAndExtraSpace(rate.service_type);
            return rate;
        });

        // serviceList.forEach(rate => {
        //     rate.service_type = this.removeTrademarkAndExtraSpace(rate.service_type);
        // });
        // return serviceList.filter((rate) => rate.service_type == bulkShippingServiceType);
    }

    getDefaultServicesFromList(serviceList: Array<any>, usps_first_class_mail) {
        serviceList = serviceList.filter(rate => {
            if (rate.service_code == 'usps_media_mail' || rate.service_code == 'usps_parcel_select') {
                return false;
            }
            if (rate.service_code == "usps_first_class_mail" && usps_first_class_mail == true) {
                return false;
            }
            return rate;
        });
        return serviceList.map(rate => {
            rate.service_type = this.removeTrademarkAndExtraSpace(rate.service_type);
            return rate;
        });
    }

    countTotalWeight(carriers) {
        let totalWeight = 0.0;

        _.forEach(carriers, (carrier: any, index) => {
            totalWeight += carrier['weight'];
        });
        return totalWeight;
    }

    removeTrademarkAndExtraSpace(name: String, rate = null) {
        name = name.replace("®", "");
        return name.trim();
    }

    sumWeight(case_array, Qty) {
        let totalWeight = 0.0;
        let quantity = 0;
        _.forEach(case_array, (eachcase: any = {}) => {
            _.forEach(eachcase.quantWeights, (eachQuan: any = {}) => {
                if (eachQuan.left != 0) {
                    quantity += parseInt(eachQuan.left);
                    if (Qty >= quantity && Qty - quantity >= 0) {
                        totalWeight += parseFloat((eachQuan.left * eachQuan.unitWeight).toString());
                        eachQuan.left = 0;
                    } else if (quantity > Qty && Qty > (quantity - parseInt(eachQuan.left)) && Qty != quantity) {
                        var spaceLeft = Qty - (quantity - parseInt(eachQuan.left));
                        totalWeight += parseFloat((spaceLeft * eachQuan.unitWeight).toString());
                        eachQuan.left -= spaceLeft;
                        return;
                    }
                }
            })
        })
        return parseFloat(parseFloat((totalWeight * 16).toString()).toFixed(2));
    }


    async calculatePackageWeight(order) {
        order.packageWeights = [];
        let caseQty = [];
        // let totalQuantity = 0;
        let totalCaseQty = [];
        let case_array = [];
        if (order.shirts) {
            _.forEach(order.shirts, (shirt: any = {}) => {
                if (shirt.totalQty > 0) {
                    if (caseQty.indexOf(shirt.color.caseQty) === -1) {
                        caseQty.push(shirt.color.caseQty)
                        totalCaseQty.push(0);
                        case_array.push([]);
                    }
                    // totalQuantity += shirt.totalQty;
                    totalCaseQty[caseQty.indexOf(shirt.color.caseQty)] += shirt.totalQty;

                    if (Object.keys(shirt.quantities).length > 0) {
                        let shirtQuants = Object.keys(shirt.quantities).filter(e => shirt.quantities[e] !== '').reduce((o, e) => { o[e] = shirt.quantities[e]; return o; }, {});
                        case_array[caseQty.indexOf(shirt.color.caseQty)].push({
                            caseqty: shirt.color.caseQty,
                            totalQty: shirt.totalQty,
                            quantWeights: Object.keys(shirtQuants).map(function (key) {
                                var cat = {
                                    id: key,
                                    quantity: parseInt(shirt.quantities[key]),
                                    unitWeight: shirt.unitWeights[key],
                                    left: parseInt(shirt.quantities[key])
                                }
                                return cat;
                            })
                        });
                    } else {
                        let unitWeight = 0.0;
                        var cat = {};

                        Object.keys(shirt.unitWeights).forEach((key) => {
                            if (shirt.unitWeights[key] > unitWeight) {
                                unitWeight = shirt.unitWeights[key];
                                cat = {
                                    id: key,
                                    quantity: parseInt(shirt.totalQty),
                                    unitWeight: shirt.unitWeights[key],
                                    left: shirt.totalQty
                                }
                            }
                        });

                        case_array[caseQty.indexOf(shirt.color.caseQty)].push({
                            caseqty: shirt.color.caseQty,
                            totalQty: shirt.totalQty,
                            quantWeights: [cat]
                        });

                    }

                }
            });

            console.log(caseQty, "case qunatities");

            _.forEach(caseQty, (cases, index) => {
                if (totalCaseQty[index] != 0) {
                    if (totalCaseQty[index] <= cases) {
                        order.packageWeights.push({
                            weight: this.sumWeight(case_array[index], cases),
                            unit: "ounce"
                        })
                    } else {
                        let noOfCasePackage = Math.ceil(totalCaseQty[index] / cases);
                        let i = noOfCasePackage;
                        let packed = totalCaseQty[index];
                        while (i >= 1) {
                            if (i == 2) {
                                order.packageWeights.push({
                                    weight: this.sumWeight(case_array[index], Math.floor(packed / 2)),
                                    unit: "ounce"
                                });
                            } else if (i == 1) {
                                order.packageWeights.push({
                                    weight: this.sumWeight(case_array[index], Math.ceil(packed / 2)),
                                    unit: "ounce"
                                });
                            } else {
                                order.packageWeights.push({
                                    weight: this.sumWeight(case_array[index], cases),
                                    unit: "ounce"
                                });
                                packed -= cases;
                            }
                            i--;
                        }
                    }
                }
            })
        }
        return order;
    }
}
